import React from "react"
import Layout from "../components/Layout/layout"
import Header from "../components/Layout/header"
import CardText from "../components/Card/cardText"
import CdCard from "../components/cd"
import "../style/style.css"

const Page = () => {
  return (
    <Layout>
      <Header header="page2" />
      <CardText image="image2" />
      <CardText image="image3" />
      <CdCard />
    </Layout>
  )
}

export default Page
