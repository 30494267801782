import React from "react"
import dataText from "../../data/text"
import CardTextImages from "./cardTextImages"
import "./cardText.css"

const cardText = ({ image }) => {
  const text = dataText.find(element => {
    return element.image === image
  })
  const paragraphes = text["paragraphe"]

  return (
    <div className="wrapper">
      <div className="container">
        <div className="imgcontainer">
          <CardTextImages image={image} />
        </div>
        <div className="textcontainer">
          <div className="title">{text.title}</div>
          {paragraphes.map((item, i) => {
            return (
              <div key={i} className={item.style}>
                {item.texte}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default cardText
