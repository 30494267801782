const dataText = [
  {
    text: "Text1",
    image: "image1",
    title: "",
    paragraphe: [
      {
        texte: `Le piano est comme une boîte magique qui livre ses secrets, il
    est à la base d'une éducation humaniste : un pont pour notre  culture générale et la voie noble des Arts et des Lettres. Je travaille uniquement sur des éditions originales et sur des
    partitions offertes par les compositeurs.`,
        style: "paragraph",
      },

      {
        texte: `Méthode originale appliquant un solfège vocal et une technique
       pour former le pianiste au métier d'interprète.`,
        style: "paragraphBold",
      },
      {
        texte: `Préparation aux concours jeunes talents Steinway, détente par le
       souffle et le yoga, analyse et culture musicale.`,
        style: "paragraphHideOnSmall",
      },
      {
        texte: `Répertoire classique, musiques actuelles, quatre mains, musique
      contemporaine ou de philosophes et de femmes compositrices.`,
        style: "paragraphBoldHideOnSmall",
      },
      {
        texte: `Préparation au baccalauréat option Musique, stages réguliers
      hors vacances scolaires à Paris et en province.`,
        style: "paragraphHideOnSmall",
      },

      {
        texte: `La famille souhaitant accueillir un groupe d'élèves béneficie d'un tarif préférentiel.
        Etre équipé d'un piano accoustique.`,
        style: `paragraph`,
      },
    ],
  },
  {
    text: "Text2",
    image: "image2",
    title: "Parcours d'une enseignante passionnée",
    paragraphe: [
      {
        texte: `Née en 1970 à Colmar en Alsace, Nathalie Guillermic consacre sa
    carrière à l'enseignement musical autour du piano depuis 1990.`,
        style: "paragraphHideOnSmall",
      },
      {
        texte: `Nathalie a suivi une formation initiale et complète à l’École
    Normale de Musique de Paris/Alfred Cortot où elle atteint le
    niveau de brevet d'enseignement avec les certificats dans les
    disciplines du solfège, du piano, de l'analyse de l'harmonie, du
    déchiffrage au piano ainsi que de l'histoire de la musique et de
    la chorale.`,
        style: "paragraph",
      },
      {
        texte: `En 1998 elle se rend au jubilé de la musique concrète à
    Montreuil-sous-Bois et elle y découvre un nouveau monde et un
    autre univers sonore par l'écoute du cristal de Baschet. Par la
    suite, l'atelier Nicolas Schöffer de la Villa des Arts lui ouvre
    ses portes afin de présenter au public le premier cristal
    d'études 5 octaves.`,
        style: "paragraphHideOnSmall",
      },
      {
        texte: `Cette période lui donne accès également à l'atelier du Cristal
    de Baschet où elle reçoit une initiation en treize leçons par
    Michel Deneuve ainsi qu'une initiation d'un nouvel instrument en
    complément de son enseignement pianistique. `,
        style: "paragraph",
      },
      {
        texte: ` En 2005, elle est invitée par les Editions Caractères à jouer du cristal au Salon
    du Livre aux côtés des poètes russes et français.`,
        style: "paragraphBold",
      },
    ],
  },

  {
    text: "Text3",
    image: "image3",
    title: "Les Harmoniques à Paris",
    paragraphe: [
      {
        texte: `Les années 2006 et 2007 marquent un cycle de cafés-concerts
      autour du piano classique au café du Chat Noir à Paris 11ème.
       `,
        style: "paragraph",
      },
      {
        texte: `
     Elle se présente dans le salon de musique "Stella" à Paris VII.
     Adaptation et gravure en studio des Classiques du Joker pour
     piano de Stéphane Blet, dans la version piano et cristal de
     Baschet.`,
        style: "paragraph",
      },
      {
        texte: `En 2012 Nathalie Guillermic entreprend la rédaction d'un mémoire
     sur plus de vingt-deux années d'exercice et de métier dans
     l'enseignement musical pour l'obtention du diplôme d’État de
  professeur de musique par la validation des acquis et de
     l'expérience.`,
        style: "paragraphBoldHideOnSmall",
      },
      {
        texte: ` Elle prépare des jeunes artistes du public à des concours
     nationaux tels que Steinway, Claude Kahn... `,
        style: "paragraphHideOnSmall",
      },
      {
        texte: `En septembre 2014,
      elle crée son auto-entreprise Les Harmoniques qui se situent à
      Paris dans le 11ème arrondissement, afin de donner des leçons de
      piano à domicile ou dans des salons de musique à Paris.`,
        style: "paragraphBold",
      },
    ],
  },
]
export default dataText
