import React from "react"
import { graphql } from "gatsby"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"

const cardTextImages = ({ image }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          image1: file(relativePath: { eq: "image1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2000, maxHeight: 1500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2: file(relativePath: { eq: "image2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 500, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3: file(relativePath: { eq: "image3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 270, maxHeight: 380) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Img
          fluid={data[image].childImageSharp.fluid}
          style={{
            width: "100%",
            boxShadow: `0 16px 24px 2px rgba(255, 255, 255, 0.14),
                0 6px 30px 5px rgba(255, 255, 255, 0.12), 0 8px 10px -7px rgba(255, 255, 255, 0.2)`,
          }}
        />
      )}
    />
  )
}

export default cardTextImages
