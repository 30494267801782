import React from "react"
import Styles from "./cd.module.css"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"

const CdCard = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          pochette: file(relativePath: { eq: "pochette.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 150, maxHeight: 150) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <div className={Styles.wrapper}>
          <div className={Styles.container}>
            <div className={Styles.imgcontainer}>
              <Img
                alt=""
                fluid={data.pochette.childImageSharp.fluid}
                style={{
                  position: "relative",
                  margin: "auto",
                  width: "100%",
                  boxShadow: `0 16px 24px 2px rgba(0, 0, 0, 0.14),
                  0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2)`,
                }}
              />
            </div>
            <div className={Styles.textcontainer}>
              <div className={Styles.title}>
                Berceuses du temps retrouvé et pièces choisies
              </div>
              <div className={Styles.subtitle}>
                34 pièces pour piano solo de 1780 à 2010
              </div>

              <div className={Styles.paragraph}>
                "Je souhaite que l'écoute de cet album vous berce d'émotions"
              </div>
              <div className={Styles.flipContainer}>
                <div className={Styles.flipTextContainer}>
                  <div className={Styles.flipText}>
                    Disponible à la vente à 25€ l'exemplaire sur commande par
                    email.
                    <div>
                      <a
                        className={Styles.link}
                        href="mailto:lesharmoniques27@orange.fr"
                      >
                        lesharmoniques27@orange.fr
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default CdCard
